import React from 'react';

import Carousel from 'nuka-carousel';

import Image from '../image';

const AboutUs = () => {
  return (
    <div className="container my-5  section-height d-flex flex-column justify-content-center align-items-center">
      <h2 className="avenir-heavy">What Our Client's Say</h2>
      <Carousel
        slidesToShow={2}
        transitionMode="scroll3d"
        framePadding="0px 30x"
        height="350px"
        autoplay={true}
        withoutControls={true}
        speed={200}
        swiping={true}
        dragging={true}
        wrapAround={true}>
        {[1, 1, 1, 1].map((item) => (
          <div class="card px-4">
            <div class="content py-4 px-3 text-center">
              <p>
                I made bacck the purchase price in just 48 hours! Thank you for
                making it pain less, pleasant. The service was execellent. I
                will refer everyone I know
              </p>
            </div>
            <div class="about text-center p-4 text-white">
              {' '}
              <img
                alt="Image placeholder"
                class="rounded-circle"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRBwgu1A5zgPSvfE83nurkuzNEoXs9DMNr8Ww&usqp=CAU"
                width="60"
              />
              <h4 class="mb-0 mt-3">Daxton Robby</h4>{' '}
              <span className="designation">Android Developer</span>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default AboutUs;
