import React from "react";

import Image from "../image";
const hero = () => {
  return (
    <div id="home_link" className="hero-container-parent section-height">
      <div className="container d-flex justify-content-center flex-md-row flex-column px-4 py-5">
        <div className="hero-right d-flex flex-column justify-content-center col-xs-12 col-md-6 text-start mx-auto my-0">
          <h1 className="hero-title display-4 avenir-heavy">Laws on Demand</h1>
          <p className="">
            Freedom is just a click away. We empower real people to make the
            best choices for themselves and their families.
          </p>
          <a href={`#download-app`} className="main-button">
            Download App
          </a>
        </div>

        <div className="hero-left d-flex justify-content-center justify-content-md-end col-xs-12 col-md-6">
          <Image src="heroimg.png" className="w-100 h-100 hero-img" />
        </div>
      </div>

      <span id="our_app"></span>
    </div>
  );
};

export default hero;
