import React from 'react';

import Image from '../image';

const Footer = () => {
  const backtotop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/* <hr /> */}
      <div className="footer-container-parent d-flex justify-content-md-center  bg-white border-top">
        <div className="container  footer-container d-flex  mx-0 row text-black pt-5 ">
          <div className="f-ul-parent d-flex align-items-center justify-content-lg-center col-10 col-md-6 col-lg-3  text-md-center mb-4 mb-md-4 mb-lg-0 pb-md-4">
            <a href="#" className="f-logo-img-link">
              <Image
                src="lod-icon.png"
                alt="img not found"
                className="f-logo-img"
              />
            </a>
          </div>

          <div className="f-ul-parent col-10 col-md-6 col-lg-3  text-md-center mb-3 mb-md-4 mb-lg-0 d-flex flex-column align-items-start  ">
            <p className="f-ul-title h6"> COMPANY</p>
            <ul className="f-ul list-unstyled d-flex flex-column align-items-start">
              <a href="#" className="text-decoration-none f-links mt-2">
                <li className="f-li ">About Us</li>
              </a>
              <a href="#" className=" text-decoration-none f-links mt-1">
                <li className="f-li">Our App</li>
              </a>
              <a href="#" className=" text-decoration-none f-links mt-1">
                <li className="f-li">Services</li>
              </a>
            </ul>
          </div>

          <div className="f-ul-parent col-10 col-md-6 col-lg-3  text-md-center mb-3 mb-md-4 mb-lg-0">
            <p className="f-ul-title h6 d-flex flex-column align-items-start ">
              CONTACT US
            </p>
            <ul className="f-ul list-unstyled d-flex flex-column align-items-start ">
              <a
                href="mailto:admin@lawzondemand@gmail.com"
                className="f-gmail-link text-decoration-none text-black f-links">
                <li className="f-li mt-2">lawzondemand@gmail.com</li>
              </a>
            </ul>
          </div>
          <div className="f-ul-parent col-10 col-md-6 col-lg-3  text-md-center mb-3 mb-md-4 mb-lg-0">
            <p className="f-ul-title h4 d-flex flex-column align-items-start  fw-bold">
              Have any Question
            </p>
            <ul className="f-ul list-unstyled d-flex flex-column align-items-start mt-3">
              <a
                href="mailto:admin@lawzondemand@gmail.com"
                className=" text-decoration-none">
                <li className="f-li contact-support-li rounded-pill text-white py-2 px-4 fw-bold">
                  Contact Support
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>

      <div className="website-creator-parent d-flex align-items-center justify-content-center text-black">
        Designed By&nbsp;&nbsp;
        <a
          href="https://ciphersavvy.com/"
          className="website-creator text-decoration-none text-black ">
          {' '}
          Cipher Savvy
        </a>
      </div>

      <div
        href="#"
        className="back-to-top-link   text-decoration-none text-white fw-bold  position-fixed rounded d-flex  justify-content-center align-items-center pt-1"
        onClick={backtotop}>
        <div className="back-to-top-icon  w-50 h-50  "></div>
      </div>
    </>
  );
};

export default Footer;
