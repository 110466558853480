import React from 'react';

import SEO from '../components/seo';
import Aboutus from '../components/landingPage/aboutus';
import AboutApp from '../components/landingPage/about_mobile';
import AppEducation from '../components/landingPage/appeducation';
import Footer from '../components/landingPage/footer';
import Header from '../components/landingPage/header';
import Hero from '../components/landingPage/hero';
import InstallApp from '../components/landingPage/installapp_sec';
import VideoSec from '../components/landingPage/videoSec';
import Testimonials from '../components/landingPage/testimonials';

const IndexPage = () => {
  return (
    <>
      <SEO title="Access 20,000 legal terms from authoritative sources trusted by attorneys" />
      <Header />
      <Hero />
      <AboutApp />
      <Aboutus />
      <VideoSec />
      <AppEducation />
      <Testimonials />
      <InstallApp />
      <Footer />
    </>
  );
};

export default IndexPage;
