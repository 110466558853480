import React from 'react';

import Image from '../image';

const About = () => {
  return (
    <div className="eduapp-container-parent py-5 section-height">
      <h2 className="eduapp-title text-center text-capitalize avenir-heavy">
        What you can find in LOD?
      </h2>
      <div className="d-flex align-items-center my-0 h-auto ">
        <div className="container eduapp-container h-auto ">
          <div className="eduapp-main d-flex flex-row justify-content-around ">
            <Image src="how-it-works.png" className="eduapp-img" />
            <div className="eduapp-para-parent">
              <div className="mb-3 width-content mx-auto">
                <h5 className="avenir-medium">1. Laws for All</h5>
                <p className="px-3">
                  Offers exclusively available for your credit/debit cards & UPI
                </p>
              </div>
              <div className="mb-3 width-content mx-auto">
                <h5 className="avenir-medium">2. Bill of Rights</h5>
                <p className="px-3">
                  Offers exclusively available for your credit/debit cards & UPI
                </p>
              </div>
              <div className="mb-3 width-content mx-auto">
                <h5 className="avenir-medium">
                  3. Constitution of Different States
                </h5>
                <p className="px-3">
                  Offers exclusively available for your credit/debit cards & UPI
                </p>
              </div>
              <div className="width-content mx-auto">
                <h5 className="avenir-medium">4. Law Codes</h5>
                <p className="px-3">
                  Offers exclusively available for your credit/debit cards & UPI
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
