import React from 'react';

import Image from '../image';

const AboutUs = () => {
  return (
    <div className="aboutus-container-parent my-5 h-auto section-height d-flex justify-content-center align-items-center">
      <div className="container py-5">
        <h1 className="aboutus-title text-center avenir-heavy">
          What the Team Says
        </h1>
        <div className="d-flex justify-content-center align-items-center  h-auto">
          <div className="containers row d-flex my-5 align-items-center">
            <div className="col-md-5 d-flex justify-content-center">
              <Image alt="about-us-img" className="w-75" src="team.png" />
            </div>
            <div className="col-md-7 ">
              <p className="aboutus-para">
                The team is always working to being real value to the life of
                people by educating them. Weather it's technology or
                informaation, we are continuously improving on it. We try to
                allign our goal, FREEDOM IS JUST A CLICK AWAY, with everything
                we do.
                <br />
                <br />
                The team is always working to being real value to the life of
                people by educating them. Weather it's technology or
                informaation, we are continuously improving on it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
