import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const Image = ({ src, placeholder = 'blurred', ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            blurred: childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
            traced: childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
        }
      }
    }
  `);
  const match = useMemo(
    () => data.allFile.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  );
  return (
    <GatsbyImage
      image={match && match.node[placeholder].gatsbyImageData}
      {...props}
    />
  );
};

Image.defaultProps = {
  placeholder: 'blurred',
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
export default Image;
