import React, { useState } from 'react';

import Image from '../image';

const Header = () => {
  const [navExp, setNavExp] = useState(false);
  const [navli, setnavli] = useState([
    { li: 'Home', id: '' },
    { li: 'About App', id: 'our_app' },
    { li: 'About Us', id: 'about_link' },
    { li: 'Services', id: 'services' },
  ]);

  const navExpand = () => {
    setNavExp(!navExp);
  };
  return (
    <div className="header-container">
      <div
        className="header-after position-fixed w-100 h-100 "
        onClick={navExpand}></div>

      <div className="h-container-parent  bg-white">
        <header className="container">
          <div className="header-main w-100 h-100 d-flex justify-content-between align-items-center">
            <a href="#" className="desktopview-header-logo h-100">
              <Image
                src="lod-icon.png"
                alt="img not found"
                className="header-logo "
              />
            </a>

            <div className="navbar-main  d-lg-block   ">
              <div className="logo-plus-cross-parent d-block d-flex justify-content-between mt-3">
                <Image src="lod-icon.png" className="nav-logo" />
                <div
                  className="nav-cross-parent d-flex flex-column justify-content-around "
                  onClick={navExpand}>
                  <div className="w-100  nav-cross nav-cross-top rounded"></div>
                  <div className="w-100 nav-cross nav-cross-bottom rounded"></div>
                </div>
              </div>
              <ul className="navbar-ul list-unstyled m d-flex justify-content-between fw-bold  h-100 mb-0">
                {navli.map((value, index) => {
                  return (
                    <a
                      href={`#${value.id}`}
                      className="nav-li-link text-decoration-none"
                      key={index}>
                      <li className="navbar-li align-self-center">
                        {value.li}
                        <div className="link-underline d-none  rounded-pill"></div>
                      </li>
                    </a>
                  );
                })}
              </ul>
            </div>

            <div
              className="t-parent-link   d-flex justify-content-end align-items-center "
              onClick={navExpand}>
              <div className="t-parent d-flex flex-column  justify-content-between  align-items-end h-50  ">
                <div className="t-lines t-top   rounded-pill w-75"></div>
                <div className="t-lines t-midddle  rounded-pill w-75"></div>
                <div className="t-lines  t-bottom  rounded-pill w-75"></div>
              </div>
            </div>
          </div>
        </header>
      </div>

      <style global="true" jsx="true">{`
        .desktopview-header-logo img {
          visibility: ${navExp ? 'hidden !important' : 'visible !important '};
        }
        .header-after {
          display: ${navExp ? 'block' : 'none '};
        }

        .navbar-main {
          left: ${navExp ? '0% !important' : '-100% !important'};
        }

        .t-lines {
          display: ${navExp ? 'none' : 'block'};
        }
      `}</style>
    </div>
  );
};

export default Header;
