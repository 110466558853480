import React from 'react';

import Image from '../image';
const VideoSec = () => {
  return (
    <>
      <div className="videosec-parent d-flex justify-content-center align-items-center my-5 py-4 section-height">
        <div className="videosec-main ">
          <div className="video-sec-top d-flex flex-column align-items-center">
            {/* <Image src="lod-icon.png" className="videosec-img" /> */}

            <h2 className="videosec-h avenir-heavy pb-3">Know Your Rights</h2>
            <p className="videosec-para text-center  ">
              Laws On Demand can help you find clear, in-depth definitions of
              legal terminology right on your Phone.
            </p>
          </div>
          <div className="video-sec-bottom d-flex justify-content-center m-auto my-lg-4 my-lg-5 px-4 px-lg-5 ">
            <iframe
              width="90%"
              height="315"
              src="https://www.youtube.com/embed/d75upaDHSvY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <span id="services"></span>
    </>
  );
};

export default VideoSec;
