import React from "react";
import Secure from "../../icons/secure.svg";
import Eye from "../../icons/eye.svg";
import Safe from "../../icons/safe.svg";
const About_mobile = () => {
  return (
    <div className="section-height d-flex justify-content-center align-items-center">
      <div className="d-flex justify-content-center my-5 h-auto">
        <div className="container mx-0 h-auto ">
          <div className="card mx-0 border-0 px-0 px-md-3  mb-5">
            <div className="card-body p-0 text-center">
              <h1 className="card-title mb-md-3 avenir-heavy">About the App</h1>
              <p className="card-text about-detail">
                Laws On Demand gives you free, instant access to more than
                20,000 legal terms from authoritative sources trusted by
                attorneys, including West's Encyclopedia of American Law. Laws
                on Demand is free and accessible for everyone.
              </p>
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column justify-content-around px-0">
            <div className="about-app-card px-3 mt-sm-3 m-sm-auto m-md-2">
              <div className="d-flex mb-3 align-items-center">
                <img alt="about-mob-img" src={Secure} />
                <h5 className="mx-3 avenir-medium">Easy</h5>
              </div>
              <p className="card-text ">
                User-friendly, comprehensive, and authoritative: Laws On Demands
                is the perfect free legal app for lawyers, legal professionals,
                law school students, and anyone looking to decipher legal jargon
                and better understand legal advice.
              </p>
            </div>
            <div className="about-app-card px-3 mt-sm-3 m-sm-auto m-md-2">
              <div className="d-flex mb-3 align-items-center">
                <img alt="about-mob-img" src={Safe} />
                <h5 className="mx-3 avenir-medium">Smart</h5>
              </div>
              <p className="card-text">
                Whether you're an attorney, law student, or looking to better
                understand complex legal advice, Laws On Demand can help you
                find clear, in-depth definitions of legal terminology right on
                your iPhone.
              </p>
            </div>
            <div className="about-app-card px-3 mt-sm-3 m-sm-auto m-md-2">
              <div className="d-flex mb-3 align-items-center">
                <img alt="about-mob-img" src={Eye} />
                <h5 className="mx-3 avenir-medium">Trusted & Free</h5>
              </div>
              <p className="card-text">
                Laws On Demand gives you free, instant access to more than
                20,000 legal terms from authoritative sources trusted by
                attorneys, including West's Encyclopedia of American Laws.
              </p>
            </div>
          </div>
        </div>
      </div>
      <span id="about_link"></span>
    </div>
  );
};

export default About_mobile;
