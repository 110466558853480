import React from "react";

const Installapp_sec = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center">
      <h2 className="mb-5 aboutus-title text-center avenir-heavy">
        Download Laws on Demand Now
      </h2>
      <div className="d-block d-md-flex mb-5 download-btn-section justify-content-around">
        <div className="m-3">
          <a
            href="https://apps.apple.com/us/app/laws-on-demand/id1512623448"
            className="app-install-link d-flex align-items-center justify-content-center rounded border"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.ibb.co/52x0yW4/favpng-app-store-download-google-play.png"
              alt=""
              className="app-install-img"
            />
          </a>
        </div>

        <div className="m-3">
          <a
            href="https://play.google.com/store/apps/details?id=com.lawondemand"
            className="app-install-link d-flex align-items-center justify-content-center rounded border"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.ibb.co/3CdLDt8/g-pay.png"
              alt=""
              className="app-install-img"
            />
          </a>
        </div>
      </div>
      <div id="download-app"></div>
    </div>
  );
};

export default Installapp_sec;
